.section {
    &__head {
        display: flex;
        flex-direction: row;
    }

    &__title {
        display: block;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        color: var(--color3);
        padding: 0;
        margin: 0;
        flex-grow: 999;
    }

    &__utils {
        flex-grow: 1;
    }
}
