.action-button {
    background-color: var(--color6);
    border: 1px solid var(--color15);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    padding: 8px 16px;
    color: var(--color1);
    transition: color var(--transition-duration) ease-in-out, border-color var(--transition-duration) ease-in-out,
        background-color var(--transition-duration) ease-in-out;

    &:hover {
        color: var(--color6);
        background-color: var(--color15);
    }

    &_pending {
        color: var(--color2);
        background-color: var(--color6);
        border-color: var(--color3);

        &:hover,
        &:active {
            cursor: default;
            color: var(--color2);
            background-color: var(--color6);
            border-color: var(--color3);
        }
    }

    &_disabled {
        border-color: var(--color21);
        color: var(--color21);
        cursor: default;

        &:hover,
        &:active {
            background-color: transparent;
            border-color: var(--color21);
            color: var(--color21);
        }
    }
}
